import {createRouter, createWebHistory} from 'vue-router'
import routes from 'vue-auto-routing'
import {createRouterLayout} from 'vue-router-layout'
import store from "@/store";
import {Mutations, Actions} from "@/store/enum/StoreEnums";

const RouterLayout = createRouterLayout(layout => {
    return import('@/layouts/' + layout + '.vue')
})

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            redirect: "/dashboard",
            component: RouterLayout,
            children: routes
        }
    ]
})

router.beforeEach((to, from, next) => {
    // reset config to initial state
    store.commit(Mutations.RESET_LAYOUT_CONFIG);
    store.commit(Mutations.RESET_FORBIDDEN_ENDPOINT);

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);

    if (to.name !== 'login' && !store.getters.isUserAuthenticated) {
        next({ name: 'login' })
    }
    else if (to.name === 'login' && store.getters.isUserAuthenticated){
        next({ path: '/' })
    }
    else {
        next()
    }
});


export default router
