import {createTableState, normalizePagination} from '@/core/services/Helper'

const state = () => ({
    table: createTableState()
})

// getters
const getters = {}

// actions
const actions = {
    // get records
    async get({commit}, {page}) {
        commit('setLoading', true)

        let params = {
            page: page.current,
            page_limit: page.pageSize
        };

        const res = await this._vm.axios.get(this._vm.endpoints['user_gender'], {
            params: params
        })

        const {data, meta} = res.data
        commit('updateTableData', data)
        commit('updatePagination', normalizePagination(meta))

        setTimeout(() => {
            commit('setLoading', false)
        }, 250)
    },

    // refresh records
    refresh({state, dispatch}) {
        dispatch('get', {
            page: state.table.pagination.current,
            page_limit: state.table.pagination.pageSize,
        })
    }
}

// mutations
const mutations = {
    setLoading(state, val) {
        state.table.loading = val
    },
    updatePagination(state, pagination) {
        state.table.pagination = pagination
    },
    updateTableData(state, data) {
        state.table.data = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
