<template>
    <router-view/>
</template>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~@yaireo/tagify/src/tagify.scss";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~element-plus/dist/index.css";

// Main demo style scss
@import "assets/sass/plugins";
@import "assets/sass/style";

// Dark mode demo style scss
//@import "assets/sass/plugins.dark";
//@import "assets/sass/style.dark";

//RTL version styles
//@import "assets/css/style.rtl.css";

@import "assets/css/app.css";

</style>

<script>
import {Actions, Mutations} from "@/store/enum/StoreEnums";

export default {
    name: "app",
    data() {
        return {
            defaultLanguage: {},
            defaultCurrency: {
                id: 1,
                code: "TRY",
                name: "Türk Lirası",
                prefix: null,
                suffix: "₺",
                rate: "0.00000",
                commission: "0.00",
                active: true,
                default: true,
                country_count: 1
            },
            languages:[],
            loadedInitialize: false,
            fileSupported: {},
            flagPath: "/media/flags/by-language-code/%s.svg",
            appUrl: process.env.VUE_APP_URL,
            questionLetters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'Y', 'V', 'W', 'X', 'Y', 'Z'],
            filterWithUrl: true,
        }
    },
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        }
    },
    created(){
        this.$store.dispatch(Actions.VERIFY_AUTH).then((response) => {
            if(response && response.status && !this.loadedInitialize) {
                this.initialize();
            }
        });
    },
    mounted() {
        this.$store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
        if(this.$store.getters.isUserAuthenticated && !this.loadedInitialize){
            this.initialize();
        }
    },
    methods: {
        initialize(){
            this.$store.dispatch("localization/language/get", {page: {}}).then((languages) => {
                this.languages = languages;
                for(let i = 0; i < languages.length; i++){
                    if(languages[i].default) {
                        this.defaultLanguage = languages[i];
                        this.afterLoadedDefaultLanguage();
                        break;
                    }
                }
            });

            this.$store.dispatch("file/getSupported").then((supported) => {
                this.$root.fileSupported = supported;
            });

            this.loadedInitialize = true;
        },
        afterLoadedDefaultLanguage(){
            this.$moment.locale(this.defaultLanguage.code);
        },
        getFileSupported(groupName){
            let supported = [];

            if(this.fileSupported.group){
                if(groupName && this.fileSupported.group[groupName]){
                    supported = this.fileSupported.group[groupName];
                } else if(!groupName) {
                    supported = this.fileSupported.group;
                }
            }

            return supported;
        },
        getLanguage(column, value){
            return this.resolveDatum(this.languages, value, column);
        },
        acceptFileSupported(groupName, prefix = ""){
            let accept = undefined;
            let supported = this.getFileSupported(groupName);

            if(supported.length) {
                let acceptArray = [];
                supported.forEach((item) => {
                    acceptArray.push(prefix+item);
                });

                accept = acceptArray.join(", ");
            }

            return accept;
        },
        getFilterWithUrl(value){
            if(this.filterWithUrl && Object.keys(this.urlParams()).length){
                value = this.urlParams();
            }

            return value;
        }
    }
}
</script>