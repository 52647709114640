import {createI18n} from "vue-i18n";

// Localisation language list
import {locale as tr} from "@/locales/i18n/tr";

let messages = {};
messages = {...messages, tr};

// get current selected language
const lang = localStorage.getItem("language") || "tr";

// Create VueI18n instance with options
const i18n = createI18n({
    legacy: false,
    locale: lang,
    globalInjection: true,
    messages,
});

export default i18n;
