import {createTableState, normalizePagination} from '@/core/services/Helper'

const state = () => ({
    table: createTableState()
})

// getters
const getters = {}

// actions
const actions = {
    //  delete record
    async delete({commit, dispatch}, {id}) {
        let successDeleted = [];
        if (id.length === 0) {
            this._vm.$message.warning(this._vm.$t('messages.selectRow'))
        } else {
            for (let i = 0; i < id.length; i++) {
                const {data} = await this._vm.axios.delete(this._vm.endpoints['module_zoom_meeting_registrant'] + '/' + id[i]);
                if (data.status) {
                    this._vm.$message.success(this._vm.$t('messages.deleteOk'));
                    successDeleted.push(id[i]);
                } else {
                    this._vm.$message.error(data.message)
                }
            }

            return successDeleted;
        }
    }
}

// mutations
const mutations = {
    setLoading(state, val) {
        state.table.loading = val
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
