import axios from "axios";
import VueAxios from "vue-axios";
import store from "@/store";
import { Actions } from "@/store/enum/StoreEnums";
import JwtService from "@/core/services/JwtService";


/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
    static axios;

    /**
     * @description initialize vue axios
     */
    static init(app) {
        app.use(VueAxios, axios);

        this.axios = app.axios;
        this.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

        this.axios.interceptors.request.use((config) => {
            store.dispatch(Actions.ACCESS, true);

            return config;
        }, (error) => {
            return Promise.reject(error);
        });

        this.axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (error.response.status == 401 && error.response.data.message != "invalid_credentials") {
                store.dispatch(Actions.LOGOUT);
                window.location.href = '/login';
            }

            if(error.response.status == 403){
                let config = error.response.config;
                store.dispatch(Actions.FORBIDDEN_ENDPOINT, {
                    url: config.url,
                    method: config.method
                });
                store.dispatch(Actions.ACCESS, false);
            }

            return Promise.reject(error);
        })
    }

    static setHeader() {
        this.axios.defaults.headers.common["Authorization"] = JwtService.get('tokenType') + ' ' + JwtService.get('token');
    }
}

export default ApiService;
