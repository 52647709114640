import {createTableState, normalizePagination, walkChildren} from '@/core/services/Helper'

const state = () => ({
    table: createTableState(),
    filterData: undefined
})

// getters
const getters = {}

// actions
const actions = {
    // get records
    async get({state, commit}, {page, filterData}) {
        commit('setLoading', true)

        state.filterData = filterData;

        let params = {
            page: page.current,
            page_limit: page.pageSize
        };

        if(state.filterData){
            params = {...params, ...state.filterData};
        }

        const res = await this._vm.axios.get(this._vm.endpoints['module_exam_question_category'], {
            params: params
        })

        const {data, meta} = res.data
        commit('updateTableData', data)
        commit('updatePagination', normalizePagination(meta))

        setTimeout(() => {
            commit('setLoading', false)
        }, 250)
    },

    async fetchChildren({commit}, {id}) {
        commit('setLoading', true)

        const res = await this._vm.axios.get(this._vm.endpoints['module_exam_question_category'], {
            params: {parent_id: id, page_limit: 9999}
        });

        const {data} = res.data
        commit('updateTableChildrenData', {data, id})

        setTimeout(() => {
            commit('setLoading', false)
        }, 250)
    },

    //  delete record
    async delete({commit, dispatch}, {id}) {
        let successDeleted = [];
        if (id.length === 0) {
            this._vm.$message.warning(this._vm.$t('messages.selectRow'))
        } else {
            for (let i = 0; i < id.length; i++) {
                const {data} = await this._vm.axios.delete(this._vm.endpoints['module_exam_question_category'] + '/' + id[i]);
                if (data.status) {
                    this._vm.$message.success(this._vm.$t('messages.deleteOk'));
                    successDeleted.push(id[i]);
                } else {
                    this._vm.$message.error(data.message)
                }
            }

            dispatch('refresh')

            return successDeleted;
        }
    },

    // refresh records
    refresh({state, dispatch}) {
        dispatch('get', {
            page: state.table.pagination,
            filterData: state.filterData
        })
    }
}

// mutations
const mutations = {
    setLoading(state, val) {
        state.table.loading = val
    },
    updatePagination(state, pagination) {
        state.table.pagination = pagination
    },
    updateTableData(state, data) {
        state.table.data = data.map(rec => {
            if (rec.has_children) {
                rec.children = []
            }
            return rec
        })
    },
    updateTableChildrenData(state, {data, id}) {
        state.table.data = walkChildren(state.table.data, {
            id: id,
            children: data.map(item => {
                return {
                    ...item,
                    ...(item.has_children ? {children: []} : {}),
                }
            }),
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
