export const createTableState = () => {
    return {
        loading: false,
        data: [],
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
    }
}

export const normalizePagination = (meta) => {
    return {
        current: meta.current_page,
        pageSize: meta.per_page,
        total: meta.total_record,
    }
}

export const walkChildren = (data, {id, children}) => {
    return data.map(row => {
        if (row.id === id) {
            row.children = children
        } else if (row.children) {
            row.children = walkChildren(row.children, {id, children})
        }
        return row
    })
}