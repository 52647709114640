import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {Actions, Mutations} from "@/store/enum/StoreEnums";

export default {
    state: () => ({
        errors: [],
        user: {},
        isAuthenticated: !!JwtService.get('token')
    }),
    getters: {
        currentUser(state) {
            return state.user;
        },
        isUserAuthenticated(state) {
            return state.isAuthenticated;
        },
        getErrors(state) {
            return state.errors;
        }
    },
    actions: {
        [Actions.LOGIN](context, credentials) {
            return new Promise((resolve, reject) => {
                return ApiService.axios.post("/auth", credentials).then(({data}) => {
                    context.commit(Mutations.SET_AUTH, data);
                    JwtService.save('tokenType', data.token.type);
                    JwtService.save('token', data.token.token);
                    ApiService.setHeader();
                    resolve(data);
                }).catch(({response}) => {
                    context.commit(Mutations.SET_ERROR, [response.data.message]);
                    reject(response);
                });
            });
        },
        [Actions.LOGOUT](context) {
            context.commit(Mutations.PURGE_AUTH);
        },
        [Actions.VERIFY_AUTH](context) {
            if (JwtService.get('token')) {
                ApiService.setHeader();
                return new Promise((resolve, reject) => {
                    ApiService.axios.get("/auth/check").then(({data}) => {
                        if (data.status) {
                            context.commit(Mutations.SET_AUTH, data);
                        } else {
                            context.commit(Mutations.PURGE_AUTH);
                        }
                        resolve(data);
                    }).catch(({response}) => {
                        context.commit(Mutations.PURGE_AUTH);
                    });
                });
            } else {
                context.commit(Mutations.PURGE_AUTH);
            }
        },
    },
    mutations: {
        [Mutations.SET_ERROR](state, error) {
            state.errors = error;
        },
        [Mutations.SET_AUTH](state, data) {
            state.isAuthenticated = true;
            state.user = data.session;
            state.errors = [];
        },
        [Mutations.SET_USER](state, user) {
            state.user = user;
        },
        [Mutations.PURGE_AUTH](state) {
            state.isAuthenticated = false;
            state.user = {};
            state.errors = [];
            JwtService.destroy('token');
            JwtService.destroy('tokenType');
        }
    }
};
