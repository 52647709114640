import {createTableState, normalizePagination} from '@/core/services/Helper';

const state = () => ({
    table: createTableState()
})

// getters
const getters = {};

// actions
const actions = {
    // get records
    async get({commit}) {
        commit('setLoading', true);

        const res = await this._vm.axios.get(this._vm.endpoints['module_form_element_type']);

        const {data} = res.data;
        commit('updateTableData', data);

        setTimeout(() => {
            commit('setLoading', false);
        }, 250)
    },

    // refresh records
    refresh({dispatch}) {
        dispatch('get');
    }
}

// mutations
const mutations = {
    setLoading(state, val) {
        state.table.loading = val;
    },
    updateTableData(state, data) {
        state.table.data = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}